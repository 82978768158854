import React, { useEffect, useState } from "react";
import icontether from "../Assets/img/Icons/icon-teather.png";
import minilogo from "../Assets/img/Icons/icon-logo.png";
import iconetherium from "../Assets/img/Icons/icon-etherium.png";
import iconsolana from "../Assets/img/Icons/icon-solana.png";
import chart from "../Assets/img/Home/chart.png";
import { Col } from "react-bootstrap";
function Tokenomics() {
  const [animatedElements, setAnimatedElements] = useState([]);

  // Function to check if an element is in the viewport
  function isElementInViewport(elem) {
    const scroll = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const elemTop = elem.getBoundingClientRect().top + scroll;

    return elemTop - scroll < windowHeight;
  }

  // Function to add the "anime" class to sections in the viewport
  function animateSections() {
    const elementsToAnimate = document.querySelectorAll(".scroll-anime");
    const elementsInViewport = [];

    elementsToAnimate.forEach((elem) => {
      if (isElementInViewport(elem)) {
        elem.classList.add("anime");
        elementsInViewport.push(elem);
      }
    });

    setAnimatedElements(elementsInViewport);
  }

  useEffect(() => {
    // Initial check on page load
    animateSections();

    // Add scroll event listener
    window.addEventListener("scroll", animateSections);

    // Cleanup on unmount
    return () => {
      window.removeEventListener("scroll", animateSections);
    };
  }, []);
  return (
    <>
      <Col xl="6" lg="6" md="6" className="scroll-anime left">
        <img src={chart} alt="Chart" />
      </Col>
      <Col xl="6" lg="6" md="6" className="scroll-anime right">
        <h2>INTL Tokenomics</h2>
        <ul className="crypto-supply-list">
          <li>
            <img src={minilogo} alt="Logo" />
            <div className="txt-pnl">
              <h6>Total Token Supply</h6>
              <h3>2,000,000,000 INTL</h3>
            </div>
          </li>
          <li>
            <img src={iconetherium} alt="Etherium" />
            <div className="txt-pnl">
              <h6>Token network</h6>
              <h3> Etheruem</h3>
            </div>
          </li>
          <li>
            <img src={icontether} alt="Tether" />
            <div className="txt-pnl">
              <h6>Estimated Launch Price</h6>
              <h3>0.11</h3>
            </div>
          </li>
        </ul>
      </Col>
    </>
  );
}
export default Tokenomics;
